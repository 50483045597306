.file-preview {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid #ddd;
    margin: 10px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    border-radius: 7px;
}

.thumbnail {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.thumbnail * {
    overflow: hidden !important;
}

.excel-thumbnail table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
}

.excel-thumbnail th,
.excel-thumbnail td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-size: 12px;
}

.excel-thumbnail th {
    background-color: #f2f2f2;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.3s;
}

.file-preview:hover .overlay {
    opacity: 1;
}

.generic-icon {
    font-size: 40px;
    color: #777;
}

.overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 10px;
    padding-top: 5px;
}

.overlay-title {
    margin-top: auto;
}

.overlay-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.iframe {
    border: none;
    position: absolute;
    top: -50%;
    left: -50%;
    overflow: hidden;
}

.full-preview-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background: #fff;
    padding: 20px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 7px;
}